<template>
  <div class="user_infod" v-if="userInfo">
    <div class="header">
      <div class="header_img">
        <img
          :src="
            userInfo.picUrl
              ? userInfo.picUrl
              : require('../../assets/normal_header.jpg')
          "
          alt=""
        />
      </div>
      <div class="name_box font33">
        <p style="font-size: 28px">{{ userInfo.name }}</p>
        <p class="font18">欢迎回来</p>
      </div>
    </div>
    <div class="bg"></div>

    <div class="info_box">
      <div class="box_title">
        <div class="left">
          <span class="font28 color33">个人信息</span>
          <span class="font14 color66">personal information</span>
        </div>
      </div>
      <div class="line"></div>

      <div class="info">
        <div class="edit" @click="toEdit">
          <i class="el-icon-edit-outline"></i>
          <span class="font14 color33">编辑</span>
        </div>
        <div class="edit pwd" @click="toChanegPwd">
          <i class="el-icon-lock"></i>
          <span class="font14 color33">修改密码</span>
        </div>
        <div class="">
          <p>
            <span>姓名：</span><span>{{ userInfo.name }}</span>
          </p>
        </div>
        <div class="">
          <p>
            <span>手机：</span><span>{{ userInfo.mobile }}</span>
          </p>
        </div>
        <div class="">
          <p>
            <span>出生日期：</span><span>{{ userInfo.birthday }}</span>
          </p>
          <p>
            <span>性别：</span
            ><span>{{
              userInfo.sex == 1 ? "男" : userInfo.sex == 2 ? "女" : "未知"
            }}</span>
          </p>
        </div>
        <div class="">
          <p>
            <span>证件类型：</span><span>{{ userInfo.idCardTypeStr }}</span>
          </p>
          <p>
            <span>证件号码：</span><span>{{ userInfo.idCard }}</span>
          </p>
        </div>
        <div class="">
          <p>
            <span>民族：</span><span>{{ nationalityStr }}</span>
          </p>
          <p>
            <span>所在单位：</span><span>{{ userInfo.workUnitName }}</span>
          </p>
        </div>
        <div class="">
          <p>
            <span>劳务关系：</span
            ><span v-if="userInfo.laborRelation == 0">无</span>
            <span v-if="userInfo.laborRelation == 1">本企业职工</span>
            <span v-if="userInfo.laborRelation == 2">劳务派遣职工</span>
            <span v-if="userInfo.laborRelation == 3">其他</span>
          </p>
          <p>
            <span>工龄：</span><span>{{ userInfo.workYear || 0 }}年</span>
          </p>
        </div>
        <div class="">
          <p>
            <span>文化程度：</span><span>{{ userInfo.educationStr }}</span>
          </p>
          <p>
            <span>考生来源：</span
            ><span v-if="userInfo.source == 0">其他人员</span>
            <span v-if="userInfo.source == 1">企业职工</span>
            <span v-if="userInfo.source == 2">在校学生</span>
            <span v-if="userInfo.source == 3">下岗失业人员</span>
            <span v-if="userInfo.source == 6">院校学生-普通大专</span>
            <span v-if="userInfo.source == 7">院校学生-普通大学</span>
            <span v-if="userInfo.source == 8">企业职工-国有企业</span>
            <span v-if="userInfo.source == 9">企业职工-集体企业</span>
            <span v-if="userInfo.source == 10">企业职工-私营企业</span>
            <span v-if="userInfo.source == 11">企业职工-个体企业</span>
          </p>
        </div>
        <div class="">
          <p>
            <span>邮箱地址：</span><span>{{ userInfo.email }}</span>
          </p>
        </div>
        <div class="">
          <p>
            <span>证书获取途径：</span
            ><span>{{
              userInfo.gainCertType == 1
                ? "自取"
                : userInfo.gainCertType == 2
                ? "邮寄"
                : "未定"
            }}</span>
          </p>
        </div>
        <div class="">
          <p>
            <span>收件人：</span
            ><span>{{ userInfo.addressee ? userInfo.addressee : "暂无" }}</span>
          </p>
          <p>
            <span>联系电话：</span
            ><span>{{
              userInfo.contactPhone ? userInfo.contactPhone : "暂无"
            }}</span>
          </p>
        </div>
        <div class="">
          <p>
            <span>详细地址：</span
            ><span>{{ userInfo.address ? userInfo.address : "暂无" }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState, mapMutations} from "vuex";
export default {
  data() {
    return {
      nationalityStr: "",
      nationalityList: [
        {value: "01", label: "汉族"},
        {value: "02", label: "蒙古族"},
        {value: "03", label: "回族"},
        {value: "04", label: "藏族"},
        {value: "05", label: "维吾尔族"},
        {value: "06", label: "苗族"},
        {value: "07", label: "彝族"},
        {value: "08", label: "壮族"},
        {value: "09", label: "布依族"},
        {value: "10", label: "朝鲜族"},
        {value: "11", label: "满族"},
        {value: "12", label: "侗族"},
        {value: "13", label: "瑶族"},
        {value: "14", label: "白族"},
        {value: "15", label: "土家族"},
        {value: "16", label: "哈尼族"},
        {value: "17", label: "哈萨克族"},
        {value: "18", label: "傣族"},
        {value: "19", label: "黎族"},
        {value: "20", label: "傈傈族"},
        {value: "21", label: "佤族"},
        {value: "22", label: "畲族"},
        {value: "23", label: "高山族"},
        {value: "24", label: "拉祜族"},
        {value: "25", label: "水族"},
        {value: "26", label: "东乡族"},
        {value: "27", label: "纳西族"},
        {value: "28", label: "景颇族"},
        {value: "29", label: "柯尔克孜族"},
        {value: "30", label: "土族"},
        {value: "31", label: "达翰尔族"},
        {value: "32", label: "仫佬族"},
        {value: "33", label: "羌族"},
        {value: "34", label: "布朗族"},
        {value: "35", label: "撒拉族"},
        {value: "36", label: "毛南族"},
        {value: "37", label: "仡佬族"},
        {value: "38", label: "锡伯族"},
        {value: "39", label: "阿昌族"},
        {value: "40", label: "普米族"},
        {value: "41", label: "塔吉克族"},
        {value: "42", label: "怒族"},
        {value: "43", label: "乌孜别克族"},
        {value: "44", label: "俄罗斯族"},
        {value: "45", label: "鄂温克族"},
        {value: "46", label: "德昂族"},
        {value: "47", label: "保安族"},
        {value: "48", label: "裕固族"},
        {value: "49", label: "京族"},
        {value: "50", label: "塔塔尔族"},
        {value: "51", label: "独龙族"},
        {value: "52", label: "鄂伦春族"},
        {value: "53", label: "赫哲族"},
        {value: "54", label: "门巴族"},
        {value: "55", label: "珞巴族"},
        {value: "56", label: "基诺族"},
        {value: "57", label: "外国血统"},
        {value: "99", label: "其他"},
      ],
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  mounted() {
    setTimeout(() => {
      for (let i in this.nationalityList) {
        if (this.nationalityList[i].value == this.userInfo.nationality) {
          this.nationalityStr = this.nationalityList[i].label;
        }
      }
    }, 500);
    console.log(this.userInfo,'wwww');
  },
  
  methods: {
    ...mapMutations(["changeShowInfo"]),
    toEdit() {
      this.changeShowInfo(true);
    },
    toChanegPwd() {
      this.$router.push("/user/changePwd");
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  height: 160px;
  // background: rebeccapurple;
  background-image: url("../../assets/user_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 10px;
  display: flex;
  align-items: center;
  .header_img {
    margin-left: 30px;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .name_box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 20px;
  }
}
.user_infod {
  position: relative;
}
.bg {
  width: 100%;
  position: absolute;
  height: 20px;
  background-color: #f5f5f5;
}
.box_title {
  padding: 30px 30px 20px;
  border-radius: 10px 10px 0 0;
}
.line {
  border: 1px solid #cccccc;
}
.info {
  width: 90%;
  margin: 20px auto;
  position: relative;
  .edit {
    position: absolute;
    right: 120px;
    top: 0px;
    font-size: 20px;
    display: flex;
    cursor: pointer;
    align-items: center;
    .el-icon-edit-outline {
      margin-right: 10px;
      color: #5586e0;
    }
  }
  .pwd {
    right: 0;
    i {
      margin-right: 10px;
      color: #5586e0;
    }
  }
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    p {
      display: flex;
      flex: 1;
      font-size: 16px;
      color: #333;
      span:first-of-type {
        width: 150px;
      }
      span:last-of-type {
        font-weight: bold;
      }
    }
  }
}
</style>
